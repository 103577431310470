import {DataInterface} from "./DataInterface";
import {CacheExpiration} from "../enums/CacheExpiration";

/* class decorator */
export function staticImplements<T>() {
    return <U extends T>(constructor: U) => (constructor);
}

export interface DataClassInterface<T extends DataInterface> {
    new(data: any):T;
    get singular(): string;
    get plural(): string;
    get cacheExpiration(): CacheExpiration;
}