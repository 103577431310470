import {Course} from "../../../data/objects/Course";
import React, {useEffect, useState} from "react";
import {Module} from "../../../data/objects/Module";
import CardComponent from "../../cards/components/CardComponent";

export default function CourseModuleListComponent({course}:{course:Course}){
    const [modules, setModules] = useState<Module[]>([]);

    useEffect(() => {
        if (course !== undefined)
            course.getChildren(Module)
                .then((modules: Module[]) => {
                    setModules(modules);
                });

    }, [course]);

    return (
        <>
            <h4>Syllabus</h4>
            <div className="card-container row">
                {
                    modules.map((module: Module) => (
                        <div key={module.id} className={"col-6"}>
                            <CardComponent data={{title:module.name, description: module.description, url: "/courses/" + course.id + "/modules/" + module.id, image: module.image}} key={module.id} />
                        </div>
                    ))
                }
            </div>
        </>
    );
}