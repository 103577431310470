import {Course} from "../../../data/objects/Course";
import {useEffect, useState} from "react";
import CardListComponent from "../../cards/components/CardListComponent";
import {Software} from "../../../data/objects/Software";

export default function CourseSoftwareListComponent({course}: {course: Course}) {
    const [softwares, setSoftwares] = useState<Software[]|undefined>(undefined);

    useEffect(() => {
        course.getChildren(Software)
            .then((softwares: Software[]) => {
                setSoftwares(softwares);
            });
    });

    if (softwares === undefined)
        return null;

    return (
        <>
            <h4>Softwares</h4>
            <CardListComponent type={Software} data={softwares} />
        </>
    );
}