import {Link} from "react-router-dom";
import {Routes} from "../../../routing/Routes";

export default function LogoComponent() {
    return (
        <Link to={Routes.index}>
            <div id={"logo"}>
                <img alt={"Linknetic LogoComponent"} src={"/img/logo/logoW.svg"}/>
            </div>
        </Link>
    );
}