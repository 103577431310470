import {BreadcrumbContainerInterface} from "../../../objects/breadcrumb/interfaces/BreadcrumbContainerInterface";
import {BreadcrumbElementInterface} from "../../../objects/breadcrumb/interfaces/BreadcrumbElementInterface";
import {Link} from "react-router-dom";

export default function BreadcrumbComponent({breadcrumbs}: {breadcrumbs: BreadcrumbContainerInterface}) {
    return (
        <nav id={"breadcrumb"}>
            <ul>
                {breadcrumbs.elements.map((breadcrumb: BreadcrumbElementInterface, index: number) => (
                    <li key={index}>
                        <Link to={breadcrumb.href}>
                            { breadcrumb.title }
                        </Link>
                    </li>
                ))}
            </ul>
        </nav>
    );
}