import {useEffect, useState} from "react";
import {Course} from "../../../data/objects/Course";
import {api} from "../../../index";
import CourseComponent from "./CourseComponent";

export default function CourseListComponent() {
    const [courses, setCourses] = useState<Course[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            const courseList = await api.getList(Course, "courses");
            setCourses(courseList);
        };

        fetchData();
    }, []);

    if (courses.length === 0)
        return null;

    return (
        <>
            <h3>Our Courses</h3>
            <div className={"card-container row"}>
                {courses.map((course: Course) => (
                    <div key={course.id} className={"col-6"}>
                        <CourseComponent course={course} />
                    </div>
                ))}
            </div>
        </>
    );
}