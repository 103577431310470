import {Course} from "../../../data/objects/Course";
import {useEffect, useState} from "react";
import {Career} from "../../../data/objects/Career";
import CardListComponent from "../../cards/components/CardListComponent";
import i18next from "i18next";

export default function CourseCareerListComponent({course}: {course: Course}) {
    const [careers, setCareers] = useState<Career[]|undefined>(undefined);

    useEffect(() => {
        course.getChildren(Career)
            .then((careers: Career[]) => {
                setCareers(careers);
            });
    });

    if (careers === undefined)
        return null;

    return (
        <>
            <h4>{i18next.t("career", {count:2})}</h4>
            <CardListComponent type={Career} data={careers} />
        </>
    );
}