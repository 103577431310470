import { library } from "@fortawesome/fontawesome-svg-core";
import { faArrowRightFromBracket, faHouse } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {LocalApiCache} from "../../../data/utils/LocalApiCache";
import {userManager} from "../../../index";

export default function LogoutComponent() {
    library.add(faHouse);

    function doLogout(): void{
        LocalApiCache.clear();
        userManager.logout();

        window.location.href = "/";
    }

    return (
        <>
            <a href={"#"} onClick={doLogout}><FontAwesomeIcon icon={faArrowRightFromBracket} className={"fa"}/>
                Logout
            </a>
        </>
    )
}