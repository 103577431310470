import React, {useEffect} from "react";
import {useLocation} from "react-router-dom";
import Cookies from "universal-cookie";

export default function ReturnAuthPage() {
    const searchParams = new URLSearchParams(useLocation().search)
    const code: string|null = searchParams.get('code');

    // const state = searchParams.get('state');
    // validate state...

    useEffect(() => {
        const retrieveToken = async (code: string) => {
            const url: string = process.env.REACT_APP_AUTH_URL + "token";
            const data: any = {
                "grant_type": "authorization_code",
                "code": code,
                "client_id": process.env.REACT_APP_AUTH_CLIENT_ID,
            }

            fetch(url,{
                method: "POST",
                body: JSON.stringify(data),
                mode: "cors",
                cache: "no-cache",
            })
                .then((response: Response) => {
                    response.json()
                        .then((json: any) => {
                            const cookies = new Cookies();
                            cookies.set("token", json.access_token);
                            window.location.href = "/";
                        });
                });
        };

        if (code !== null)
            retrieveToken(code);

    }, [code]);

    return (
        <>
            <div>waiting...</div>
        </>
    );
}