import { library } from "@fortawesome/fontawesome-svg-core";
import { faHouse, faTv } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {userManager} from "../../../index";
import AdministrationMenuComponent from "../../administration/components/AdministrationMenuComponent";
import EducationalAdministrationMenu from "../../educational-administration/components/EducationalAdministrationMenu";
import {Link} from "react-router-dom";
import {Routes} from "../../../routing/Routes";

export default function MenuContainerComponent() {
    library.add(faHouse);
    return (
        <div className={"mainMenu"}>
            <li className={"item"} id={"dashboard"}>
                <Link to={Routes.index} className={"btn"}><FontAwesomeIcon icon={faHouse} className={"fa"}/>Dashboard</Link>
            </li>
            <li className={"item"} id={"courses"}>
                <a href="#courses" className={"btn"}><FontAwesomeIcon icon={faTv} className={"fa"}/>Courses</a>
                <div className={"subMenu"}>
                    <a href="">Explore</a>
                    <a href="">List</a>
					<a href="">Detail</a>
                </div>
            </li>

            
            {userManager.isEducationalAdministrator && <EducationalAdministrationMenu />}
            {userManager.isAdministrator && <AdministrationMenuComponent />}
        </div>
    );
}