import {Course} from "../../../data/objects/Course";
import {useEffect, useState} from "react";
import {Instructor} from "../../../data/objects/Instructor";
import i18next from "i18next";
import CardHorizontalComponent from "../../cards/components/CardHorizontalComponent";

export default function CourseInstructorListComponent({course}: {course:Course}) {
    const [instructors, setInstructors] = useState<Instructor[]>([]);

    useEffect(() => {
        course.getChildren(Instructor)
            .then((instructors: Instructor[]) => {
                setInstructors(instructors);
            });
    });

    if (instructors.length === 0)
        return null;

    return (
        <>
            <h4>{i18next.t("instructor", {count: 2})}</h4>
            {instructors.map((instructor: Instructor) => (
                <CardHorizontalComponent type={Instructor} data={instructor} key={instructor.id}/>
            ))}
        </>
    );
}