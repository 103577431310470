import {DataClassInterface} from "../interfaces/DataClassInterface";
import {DataInterface} from "../interfaces/DataInterface";

export class DataFactory {
    public static createList<T extends DataInterface>(
        objectClass: DataClassInterface<T>,
        data: any,
    ): T[] {
        const response: T[] = [];

        if (data.data === undefined)
            return [];

        if (data.data.attributes !== undefined){
            response.push(new objectClass(data.data));
        } else {
            for(let index=0; index<data.data.length; index++){
                response.push(new objectClass(data.data[index]));
            }
        }

        return response;
    }

    public static create<T extends DataInterface>(
        objectClass: DataClassInterface<T>,
        data: any,
    ): T {
        return new objectClass(data);
    }
}