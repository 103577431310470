import React from "react";
import NavigationComponent from "../../structure/components/NavigationComponent";
import TitleComponent from "../../structure/components/TitleComponent";
import BreadcrumbComponent from "../../structure/components/BreadcrumbComponent";
import {BreadcrumbContainer} from "../../../objects/breadcrumb/BreadcrumbContainer";
import {BreadcrumbElement} from "../../../objects/breadcrumb/BreadcrumbElement";
import CourseListComponent from "../../courses/components/CourseListComponent";
import FooterComponent from "../../structure/components/FooterComponent";
import {Routes} from "../../../routing/Routes";
import i18next from "i18next";

export default function DashboardPage() {
    return (
        <>
            <NavigationComponent />
            <main>
                <div className="container">
                    <div className="row">
                        <TitleComponent title={i18next.t("main_title")} />
                        <BreadcrumbComponent breadcrumbs={new BreadcrumbContainer()
                            .addElement(new BreadcrumbElement(i18next.t("dashboard"), Routes.index))
                        } />
                    </div>
                    <div className="row">
                        <CourseListComponent />
                    </div>
                </div>
            </main>
            <FooterComponent />
        </>
    );
}