import LogoComponent from "./LogoComponent";
import UserIconComponent from "./UserIconComponent";
import MenuContainerComponent from "./MenuContainerComponent";

export default function NavigationComponent() {
    return (
        <div id={"nav"} className={"nav-container d-flex active-element"}>
            <div className={"nav-content d-flex"}>
                <LogoComponent />
                <UserIconComponent />
                <MenuContainerComponent />
            </div>
        </div>
    );
}