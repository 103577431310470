import {userManager} from "../../../index";
import EducationalAdmnistrationCourseList from "../components/EducationalAdmnistrationCourseList";
import NavigationComponent from "../../structure/components/NavigationComponent";
import React from "react";
import TitleComponent from "../../structure/components/TitleComponent";
import BreadcrumbComponent from "../../structure/components/BreadcrumbComponent";
import {BreadcrumbContainer} from "../../../objects/breadcrumb/BreadcrumbContainer";
import {BreadcrumbElement} from "../../../objects/breadcrumb/BreadcrumbElement";
import FooterComponent from "../../structure/components/FooterComponent";
import {Routes} from "../../../routing/Routes";
import i18next from "i18next";

export default function EducationalAdmnistrationCourseListPage() {
    if (!userManager.isEducationalAdministrator)
        window.location.replace('/401');

    return (
        <>
            <NavigationComponent />
            <main>
                <div className="container">
                    <div className="row">
                        <TitleComponent title={i18next.t("educational_administration_course_list")} />
                        <BreadcrumbComponent breadcrumbs={new BreadcrumbContainer()
                            .addElement(new BreadcrumbElement(i18next.t("dashboard"), Routes.index))
                            .addElement(new BreadcrumbElement(i18next.t("course_list"), Routes.educationalAdministration_CourseList))
                        } />
                    </div>
                    <div className="row">
                        <EducationalAdmnistrationCourseList />
                    </div>
                </div>
            </main>
            <FooterComponent />
        </>
    );
}