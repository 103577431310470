import {BaseDataInterface} from "../../../data/interfaces/BaseDataInterface";
import {Link} from "react-router-dom";
import {DataClassInterface} from "../../../data/interfaces/DataClassInterface";
import {DataInterface} from "../../../data/interfaces/DataInterface";

export default function CardHorizontalComponent({type, data}: {type: DataClassInterface<DataInterface>, data: BaseDataInterface}) {
    return(
        <div className="row" key={data.id}>
            <div className="col">
                <div className="card">
                    <div className="row">
                        {
                            data.image &&
                            <div className="col-lg-auto card-img-left">
                                <img src={data.image} alt="course" height={"53"}/>
                            </div>
                        }
                        <div className="col-6 col-lg h-100">
                            <div className="card-body">
                                <Link to={"/" + type.plural + "/" + data.id}>
                                    {data.name}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}