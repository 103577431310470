import {useParams} from "react-router-dom";
import NavigationComponent from "../../structure/components/NavigationComponent";
import TitleComponent from "../../structure/components/TitleComponent";
import BreadcrumbComponent from "../../structure/components/BreadcrumbComponent";
import {BreadcrumbContainer} from "../../../objects/breadcrumb/BreadcrumbContainer";
import {BreadcrumbElement} from "../../../objects/breadcrumb/BreadcrumbElement";
import React, {useEffect, useState} from "react";
import {Course} from "../../../data/objects/Course";
import {api} from "../../../index";
import CourseAtAGlanceComponent from "../components/CourseAtAGlanceComponent";
import CourseModuleListComponent from "../components/CourseModuleListComponent";
import CourseInstructorListComponent from "../components/CourseInstructorListComponent";
import CardComponent from "../../cards/components/CardComponent";
import i18next from "i18next";
import CourseCareerListComponent from "../components/CourseCareerListComponent";
import CourseSoftwareListComponent from "../components/CourseSoftwareListComponent";
import CourseRequirementListComponent from "../components/CourseRequirementListComponent";
import FooterComponent from "../../structure/components/FooterComponent";
import {Routes} from "../../../routing/Routes";
import {LinkRouter} from "../../../routing/LinkRouter";

export default function CoursePage() {
    const { courseId } = useParams();

    const [course, setCourse] = useState<Course|undefined>(undefined);

    useEffect(() => {
        api.getSingle(Course, "courses/" + courseId)
            .then((downloadedCourse: Course|undefined) => {
                if (downloadedCourse !== undefined) {
                    setCourse(downloadedCourse);
                }
            })
    }, [courseId]);

    if (course === undefined)
        return null;

    return (
        <>
            <NavigationComponent />
            <main>
                <div className="container">
                    <div className="row">
                        <TitleComponent title={course.name} />
                        <BreadcrumbComponent breadcrumbs={new BreadcrumbContainer()
                            .addElement(new BreadcrumbElement(i18next.t("dashboard"), Routes.index))
                            .addElement(new BreadcrumbElement(course.name, LinkRouter.getLink(Routes.course, courseId)))
                        } />
                    </div>
                    <div className="row">
                        <div className={"col-12 col-xxl-8 mb-5"}>
                            <h4>{i18next.t("preview")}</h4>
                            <CardComponent data={{description: course.description, url: "/courses/" + course.id, image: course.image}} key={course.id} />
                            <CourseModuleListComponent course={course} />
                        </div>
                        <div className={"col-12 col-xxl-4 mb-n5"}>
                            <CourseAtAGlanceComponent course={course} />
                            <CourseInstructorListComponent course={course} />
                            <CourseCareerListComponent course={course} />
                            <CourseSoftwareListComponent course={course} />
                            <CourseRequirementListComponent course={course}/>
                        </div>
                    </div>
                </div>
            </main>
            <FooterComponent />
        </>
    )
}