import {Course} from "../../../data/objects/Course";
import {useEffect, useState} from "react";
import {Area} from "../../../data/objects/Area";
import {Format} from "../../../data/objects/Format";
import {Level} from "../../../data/objects/Level";
import CardComponent from "../../cards/components/CardComponent";
import i18next from "i18next";

function getLevels(levels: Level[]): string {
    let response: string = "";

    levels.map((level: Level, current:number, rows: Level[]) => {
        response += level.name;
        if (current + 1 !== rows.length)
            response += " | ";

    });

    return response;
}

export default function CourseComponent({course}: {course: Course}) {
    const [area, setArea] = useState<string|undefined>(undefined);
    const [format, setFormat] = useState<Format|undefined>(undefined);
    const [levels, setLevels] = useState<Level[]|undefined>(undefined);

    useEffect(() => {
        const fetchFormat = async () => {
            setFormat(await course.getChild(Format));
        };

        const fetchLevels = async () => {
            const levels = await course.getChildren(Level);
            if (levels.length !== 0)
                setLevels(levels);

        };

        course.getChildren(Area)
            .then((areas: Area[]) => {
                if (areas.length !== 0) {
                    let response = '';
                    for (let index=0; index<areas.length; index++){
                        response += areas[index].name;

                        if (index < areas.length-1)
                            response += ' | ';
                    }

                    setArea(response);
                }
            });

        fetchFormat();
        fetchLevels();
    }, [course]);

    return (
        <CardComponent data={{title:course.name, description: course.blurb, url: "/courses/" + course.id, subtitle: format?.name, footnote: (area ? area : "") + (levels && " (" + getLevels(levels) + ")"), image: course.image}} key={course.id} />
    );
}