import {userManager} from "../../../index";
import {Routes} from "../../../routing/Routes";

export default function LogoutPage() {
    userManager.logout();

    return (
        <>{
            window.location.replace(Routes.index)
        }</>
    );
}