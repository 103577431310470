import {userManager} from "../../../index";
import LogoutComponent from "../../oauth/components/LogoutComponent";

export default function UserIconComponent() {
    return (
        <div id={"user-icon"}>
            <img className={"profile"} alt={userManager.user?.firstName + " " + userManager.user?.lastName} src={userManager.user?.image} width={"100px"}/>
            <div><h5>{userManager.user?.firstName + " " + userManager.user?.lastName}</h5></div>
            <div id={"logout"}><LogoutComponent /></div>
        </div>
    );
}