import {AbstractData} from "./AbstractData";
import {BaseDataInterface} from "../interfaces/BaseDataInterface";

export abstract class AbstractBaseData extends AbstractData implements BaseDataInterface {
    private _name: string;
    private _description: string;

    constructor(data: any) {
        super(data);

        this._name = data.attributes.name;
        this._description = data.attributes.description ?? "";
    }

    get name(): string {
        return this._name;
    }

    get description(): string {
        return this._description;
    }
}