import {Course} from "../../../data/objects/Course";
import {useEffect, useState} from "react";
import CardListComponent from "../../cards/components/CardListComponent";
import {Requirement} from "../../../data/objects/Requirement";

export default function CourseRequirementListComponent({course}: {course: Course}) {
    const [requirements, setRequirements] = useState<Requirement[]|undefined>(undefined);

    useEffect(() => {
        course.getChildren(Requirement)
            .then((requirements: Requirement[]) => {
                setRequirements(requirements);
            });
    });

    if (requirements === undefined)
        return null;

    return (
        <>
            <h4>Requirements</h4>
            <CardListComponent type={Requirement} data={requirements} />
        </>
    );
}