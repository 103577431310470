import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {UserManagement} from "./data/utils/UserManagement";
import {UserManagementInterface} from "./data/interfaces/UserManagementInterface";
import {ApiCallerInterface} from "./data/interfaces/ApiCallerInterface";
import {ApiCaller} from "./data/utils/ApiCaller";
import i18next from "i18next";
import Backend from "i18next-http-backend";
import {initReactI18next} from "react-i18next";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

export const userManager: UserManagementInterface = new UserManagement();
export const api: ApiCallerInterface = new ApiCaller();

i18next
    .use(Backend)
    .use(initReactI18next)
    .init({
        lng: "en",
        fallbackLng: "en",
        initImmediate: false,
    }).then(() => {
    userManager.initialise()
        .then(() => {
            root.render(
                <App />
            );
        });
    });