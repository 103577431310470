import Cookies from "universal-cookie";
import {DataFactory} from "../factories/DataFactory";
import {DataClassInterface} from "../interfaces/DataClassInterface";
import {DataInterface} from "../interfaces/DataInterface";
import {ApiCallerInterface} from "../interfaces/ApiCallerInterface";
import {CacheExpiration} from "../enums/CacheExpiration";
import {LocalApiCache} from "./LocalApiCache";

export class ApiCaller implements ApiCallerInterface{
    async getSingle<T extends DataInterface>(
        objectClass: DataClassInterface<T>,
        endpoint: string,
        cache?: CacheExpiration,
    ): Promise<T|undefined> {
        const url = this.generateUrl(endpoint);
        cache = cache ?? objectClass.cacheExpiration;
        const data = await this.fetch(url, cache);

        if (cache !== CacheExpiration.NoCache)
            LocalApiCache.setCache(url, data);

        const jsonApi = JSON.parse(data);

        if (jsonApi.data === undefined)
            return undefined;

        if (Array.isArray(jsonApi.data))
            return DataFactory.create(objectClass, jsonApi.data[0]);

        return DataFactory.create(objectClass, jsonApi.data);
    }

    async getList<T extends DataInterface>(
        objectClass: DataClassInterface<T>,
        endpoint: string,
        cache?: CacheExpiration,
    ): Promise<T[]> {
        const url = this.generateUrl(endpoint);
        cache = cache ?? objectClass.cacheExpiration;
        let data = await this.fetch(url, cache);

        const jsonApi = JSON.parse(data);

        let requiresFurtherLoading = false;

        if (jsonApi.data !== undefined) {
            if (Array.isArray(jsonApi.data)) {
                for (let index=0; index<jsonApi.data.length; index++){
                    if (jsonApi.data[index].attributes === undefined){
                        const childData = await this.fetch(jsonApi.data[index].links.self, cache);

                        if (cache !== CacheExpiration.NoCache)
                            LocalApiCache.setCache(jsonApi.data[index].links.self, childData);

                        const childJsonApi = JSON.parse(childData)

                        jsonApi.data[index] = childJsonApi.data;

                        requiresFurtherLoading = true;
                    }
                }
            } else {
                if (jsonApi.data.attributes === undefined){
                    const childData = await this.fetch(jsonApi.data.links.self, cache);

                    if (cache !== CacheExpiration.NoCache)
                        LocalApiCache.setCache(jsonApi.data.links.self, childData);

                    const childJsonApi = JSON.parse(childData);
                    jsonApi.data = childJsonApi.data;

                    requiresFurtherLoading = true;
                }
            }
        }

        if (requiresFurtherLoading){
            data = JSON.stringify(jsonApi);
        }

        if (cache !== CacheExpiration.NoCache)
            LocalApiCache.setCache(url, data);

        return DataFactory.createList(objectClass, jsonApi);
    }

    private generateUrl(
        endpoint: string,
    ): string {
        if (endpoint.startsWith("http"))
            return (endpoint);

        return (process.env.REACT_APP_API_URL ?? "") + endpoint;
    }

    private async fetch(
        url: string,
        cache?: CacheExpiration,
    ): Promise<string> {
        if (!url.startsWith("http"))
            url = (process.env.REACT_APP_API_URL ?? "") + url;

        let cachedResult: string|null = null;

        if (cache !== CacheExpiration.NoCache)
            cachedResult = LocalApiCache.getCache(url);

        if (cachedResult !== null)
            return cachedResult

        const cookies = new Cookies();
        const bearer = cookies.get("token");
        const requestInit: RequestInit = {};
        const headersInit: HeadersInit = {};
        headersInit.Authorization = "Bearer " + bearer;
        requestInit.headers = headersInit;
        requestInit.cache = "no-cache";

        let expire: string|null = null;
        const data: string = await fetch(url, requestInit)
            .then((response: Response) => {
                expire = response.headers.get('Expires');

                return response.text();
            });

        if (cache !== CacheExpiration.NoCache && expire !== null) {
            const expirationDate = new Date(expire);
            if (expirationDate > new Date(Date.now()))
                LocalApiCache.setCacheExpiration(url, expirationDate);

        }

        return data;
    }
}

// export class ApiCaller implements ApiCallerInterface{
//     private cleanUrl(
//         url: string,
//     ): string {
//         if (url.startsWith("http"))
//             return (url);
//
//         return (process.env.REACT_APP_API_URL ?? "") + url;
//     }
//
//     private get requestInit(): RequestInit {
//         const cookies = new Cookies();
//         const bearer = cookies.get("token");
//
//         const response: RequestInit = {};
//         const headersInit: HeadersInit = {};
//         headersInit.Authorization = "Bearer " + bearer;
//         response.headers = headersInit;
//         response.cache = "no-cache";
//
//         return response;
//     }
//
//     private async loadData<T extends DataInterface>(
//         objectClass: DataClassInterface<T>,
//         resourceObject: any,
//     ): Promise<T> {
//         if (resourceObject.attributes !== undefined)
//             return DataFactory.create(objectClass, resourceObject);
//
//         return this.getSingle(objectClass, resourceObject.links.self);
//     }
//
//     public async get<T extends DataInterface>(
//         objectClass: DataClassInterface<T>,
//         endpoint: string,
//         cache?: CacheExpiration,
//     ): Promise<T[]> {
//         const url = this.cleanUrl(endpoint);
//         let cachedResult = null;
//
//         if (cache === undefined)
//             cache = objectClass.cacheExpiration;
//
//         if (cache !== CacheExpiration.NoCache)
//             cachedResult = LocalApiCache.getCache(url);
//
//         if (cachedResult !== null)
//             return DataFactory.createList(objectClass, JSON.parse(cachedResult));
//
//         let expire: string|null = null;
//         const data = await fetch(url, this.requestInit)
//             .then((response: Response) => {
//                 expire = response.headers.get('Expires')
//
//                 return response.text();
//             });
//
//         const response: Promise<T>[] = [];
//
//         const jsonResponse = JSON.parse(data);
//
//         if (jsonResponse.data !== undefined) {
//             if (Array.isArray(jsonResponse.data)) {
//                 jsonResponse.data.forEach((jsonapi: any) => {
//                     response.push(this.loadData(objectClass, jsonapi));
//                 })
//             } else {
//                 response.push(this.loadData(objectClass, jsonResponse.data));
//             }
//         }
//
//         return Promise.all(response)
//             .then((response: T[]) => {
//                 const jsonApiData: any = {
//                     data: response
//                 };
//
//                 const data = JSON.stringify(jsonApiData);
//
//                 if (expire !== null) {
//                     const expirationDate = new Date(expire);
//                     if (expirationDate > new Date(Date.now()))
//                         LocalApiCache.setCacheExpiration(url, expirationDate);
//
//                 }
//
//                 LocalApiCache.setCache(url, data);
//
//                 return response;
//             });
//     }
//
//     private async fetchSingle<T extends DataInterface>(
//         objectClass: DataClassInterface<T>,
//         endpoint: string,
//         cache: CacheExpiration = CacheExpiration.NoCache,
//     ): Promise<string> {
//         const url = this.cleanUrl(endpoint);
//
//         let cachedResult = null;
//         let response = "";
//
//         if (cache === undefined)
//             cache = objectClass.cacheExpiration;
//
//         if (cache !== CacheExpiration.NoCache)
//             cachedResult = LocalApiCache.getCache(url);
//
//         if (cachedResult !== null)
//             return cachedResult;
//
//         return fetch(url, this.requestInit)
//             .then((value: Response) => {
//                 const response = value.text();
//
//                 const expire: string|null = value.headers.get('Expires');
//
//                 if (expire !== null) {
//                     const expirationDate = new Date(expire);
//                     if (expirationDate > new Date(Date.now()))
//                         LocalApiCache.setCacheExpiration(url, expirationDate);
//
//                 }
//
//                 return response;
//             })
//             .then((response: string) => {
//                 LocalApiCache.setCache(url, response);
//
//                 return response;
//             });
//     }
//
//     public async getSingle<T extends DataInterface>(
//         objectClass: DataClassInterface<T>,
//         endpoint: string,
//         cache: CacheExpiration = CacheExpiration.NoCache,
//     ): Promise<T> {
//         return this.fetchSingle(objectClass, endpoint, cache)
//             .then((response: string) => {
//                 const list = DataFactory.createList(objectClass, JSON.parse(response));
//                 return list[0];
//             });
//     }
//
//     public async post<T extends DataInterface>(
//         objectClass: DataClassInterface<T>,
//         endpoint: string,
//         data: any,
//     ): Promise<void> {
//
//     }
//
//     public async postGeneral(
//         endpoint: string,
//         data: any,
//     ): Promise<void> {
//
//     }
// }