import {DataClassInterface, staticImplements} from "../interfaces/DataClassInterface";
import {DataInterface} from "../interfaces/DataInterface";
import {BaseDataInterface} from "../interfaces/BaseDataInterface";
import {CacheExpiration} from "../enums/CacheExpiration";
import {AbstractBaseData} from "../abstracts/AbstractBaseData";

@staticImplements<DataClassInterface<DataInterface>>()
export class Software extends AbstractBaseData implements BaseDataInterface {
    static get singular(): string {
        return "software";
    }

    static get plural(): string {
        return "softwares";
    }

    static get cacheExpiration(): CacheExpiration {
        return CacheExpiration.Forever;
    }

    private _url: string;

    constructor(data: any) {
        super(data);

        this._url = data.attributes.url;
    }

    get url(): string {
        return this._url;
    }
}