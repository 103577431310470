import {Route} from "react-router-dom";
import IndexPage from "../modules/visitors/pages/IndexPage";
import CoursePage from "../modules/courses/pages/CoursePage";
import ReturnAuthPage from "../modules/oauth/pages/ReturnAuthPage";
import Error401 from "../modules/errors/pages/Error401";
import {userManager} from "../index";
import EducationalAdmnistrationCourseListPage
    from "../modules/educational-administration/pages/EducationalAdmnistrationCourseListPage";
import React from "react";
import {Routes} from "./Routes";
import DashboardPage from "../modules/dashboard/pages/DashboardPage";
import LogoutPage from "../modules/oauth/pages/LogoutPage";
import Error404 from "../modules/errors/pages/Error404";

export default function AppRouter() {
    return (
        <>
            {/* Courses */}
            <Route path={Routes.course} element={<CoursePage />}/>

            {/* Errors */}
            <Route path={Routes.e401} element={<Error401/>} />

            {/* OAuth */}
            <Route path={Routes.returnAuth} element={<ReturnAuthPage />} />
            <Route path={Routes.logout} element={<LogoutPage />} />
            {
                userManager.isLoggedIn ?
                    <>
                        <Route path={Routes.index} element={<DashboardPage />} />
                        {
                            userManager.isEducationalAdministrator &&
                            <>
                                <Route path={Routes.educationalAdministration_CourseList} element={<EducationalAdmnistrationCourseListPage />} />
                            </>
                        }

                    </> :
                    <>
                        <Route path={Routes.index} element={<IndexPage />} />
                    </>
            }

            <Route path="*" element={<Error404 />} />
        </>
    );
}