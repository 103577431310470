export enum Routes {
    index="/",

    /* Main Pages */
    course="/courses/:courseId",

    /* OAuth */
    returnAuth="/returnauth",
    logout="/logout",

    /* Errors */
    e401="/401",

    /* Educational Administration */
    educationalAdministration_CourseList="/educational-administration/course-list",
}