import {DataInterface} from "../interfaces/DataInterface";
import {DataClassInterface, staticImplements} from "../interfaces/DataClassInterface";
import {CacheExpiration} from "../enums/CacheExpiration";
import i18next from "i18next";
import {BaseDataInterface} from "../interfaces/BaseDataInterface";
import {AbstractBaseData} from "../abstracts/AbstractBaseData";

@staticImplements<DataClassInterface<DataInterface>>()
export class Course extends AbstractBaseData implements BaseDataInterface {
    static get singular(): string {
        return "course";
    }

    static get plural(): string {
        return "courses";
    }

    static get cacheExpiration(): CacheExpiration {
        return CacheExpiration.Hour;
    }

    private _blurb: string;
    private _duration: number;
    private _delivery: string;

    constructor(data: any) {
        super(data);

        this._blurb = data.attributes.blurb;
        this._duration = data.attributes.duration;
        this._delivery = i18next.t(data.attributes.deliveryRequirements);
    }

    get blurb(): string {
        return this._blurb;
    }

    get duration(): number {
        return this._duration;
    }

    get delivery(): string {
        return this._delivery;
    }
}