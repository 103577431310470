import Cookies from "universal-cookie";

export class LocalApiCache {
    static getCache(url: string): string|null {
        const response = localStorage.getItem(url);

        if (response !== null){
            const expiration = localStorage.getItem('expiration' + url);
            if (expiration !== null){
                const expirationDate = new Date(expiration);
                if (expirationDate > new Date(Date.now()))
                    return response;

            }
        }

        localStorage.removeItem(url);
        localStorage.removeItem('expiration' + url);

        return null;
    }

    static setCacheExpiration(url: string, expiration: Date) : void {
        localStorage.setItem('expiration' + url, expiration.toISOString());
    }

    static setCache(url: string, data: string): void {
        if (localStorage.getItem('expiration' + url) !== null)
            localStorage.setItem(url, data);

    }

    static clear(): void {
        const cookies = new Cookies();
        localStorage.clear();
        cookies.remove('user');
    }

    static dump(): void {
        const cookies = new Cookies();
        console.warn(localStorage);
        console.warn("User:", cookies.get("user"));
    }
}