import Cookies from "universal-cookie";
import {User} from "../objects/User";
import {DataFactory} from "../factories/DataFactory";
import {UserManagementInterface} from "../interfaces/UserManagementInterface";
import {api} from "../../index";
import {Group} from "../objects/Group";

export class UserManagement implements UserManagementInterface{
    private _token: string|undefined;
    private _userData: any|undefined;
    private _user: User|undefined;
    private _userGroups: Group[]|undefined;

    constructor() {
        const cookies = new Cookies();

        // cookies.remove('token');
        // cookies.remove('user');

        this._token = cookies.get('token');
    }

    logout(): void {
        const cookies = new Cookies();
        cookies.remove('token');
        cookies.remove('user');
    }

    async initialise(): Promise<void> {
        const cookies = new Cookies();

        if (this._token !== undefined) {
            this._userData = cookies.get("user");

            if (this._userData !== undefined){
                this._user = DataFactory.create(User, this._userData);
            } else {
                try {
                    await api.getSingle(User, "users/me")
                        .then((user: User | undefined) => {
                            if (user === undefined)
                                throw new Error('User not foud');

                            this._user = user;
                            this._userData = user.data;
                            cookies.set("user", this._userData);
                        });
                } catch (e) {
                    this._userData = undefined;
                    this._user = undefined;
                    this._token = undefined;
                    this._userGroups = undefined;
                    this.logout();
                }
            }

            if (this._user !== undefined)
                this._userGroups = await this._user.getChildren(Group);

        }
    }

    get isLoggedIn(): boolean {
        return this._token !== undefined && this._user !== undefined;
    }

    get token(): string|undefined {
        return this._token;
    }

    get user(): User|undefined {
        return this._user;
    }

    get groups(): Group[] {
        return this._userGroups ?? [];
    }

    get isEducationalAdministrator(): boolean {
        return this.isInGroup(process.env.REACT_APP_AUTH_EDUCATIONAL_ADMINISTRATORS);
    }

    get isAdministrator(): boolean {
        return this.isInGroup(process.env.REACT_APP_AUTH_ADMINISTRATORS);
    }

    private isInGroup(
        groupId?: string,
    ): boolean {
        if (this._userGroups === undefined || groupId === undefined)
            return false;

        for (let index=0; index<this._userGroups.length; index++)
            if (this._userGroups[index].id === groupId)
                return true;

        return false;
    }
}