import React from 'react';
import { BrowserRouter as Router, Routes} from 'react-router-dom';
import AppRouter from "./routing/AppRouter";

function App() {
  return (
      <Router>
          <Routes>
              {AppRouter()}
          </Routes>
      </Router>
  );
}

export default App;