import {Link} from "react-router-dom";
import {Routes} from "../../../routing/Routes";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSchoolLock} from "@fortawesome/free-solid-svg-icons";
import {library} from "@fortawesome/fontawesome-svg-core";

export default function EducationalAdministrationMenu(){
    library.add(faSchoolLock);
    return (
        <li className={"item"} id={"educationaladministration"}>
            <a href="#educationaladministration" className={"btn"}><FontAwesomeIcon icon={faSchoolLock} className={"fa"}/>Educational</a>
            <div className={"subMenu"}>
                <Link to={Routes.educationalAdministration_CourseList}>Manage Courses</Link>
            </div>
        </li>
    );
}