import {DataType} from "../enums/DataType";
import {ApiCaller} from "../utils/ApiCaller";
import {DataInterface} from "../interfaces/DataInterface";
import {DataClassInterface} from "../interfaces/DataClassInterface";
import {CacheExpiration} from "../enums/CacheExpiration";

export abstract class AbstractData {
    protected _data: any;
    private _type: string;
    private _id: string;
    private _children: Map<DataClassInterface<any>, DataInterface|DataInterface[]> = new Map<DataClassInterface<any>, DataInterface[]>();

    protected _api: ApiCaller;

    constructor(data: any) {
        this._api = new ApiCaller();

        this._data = data;
        this._type = data.type;
        this._id = data.id;
    }

    get data(): any {
        return this._data;
    }

    get type(): string{
        return this._type;
    }

    get id(): string {
        return this._id;
    }

    async getChildren<T extends DataInterface>(
        type: DataClassInterface<T>
    ): Promise<T[]> {
        const response = this._children.get(type);

        if (response !== undefined)
            return response as T[];

        return this.getRelatedList(type)
            .then((list: T[]) => {
                this._children.set(type, list);

                return list;
            });
    }

    async getChild<T extends DataInterface>(
        type: DataClassInterface<T>
    ): Promise<T> {
        const response = this._children.get(type);

        if (response !== undefined)
            return response as T;

        return this.getRelatedSingle(type)
            .then((child: T) => {
                this._children.set(type, child);

                return child;
            });
    }

    get image(): string|undefined {
        return this._data.links.image;
    }

    getLink(type: string): string|undefined {
        return this._data?.links[type];
    }

    getRelationshipLink(
        type: DataType
    ): string|undefined {
        return this?._data?.relationships[type]?.links?.related;
    }

    protected async getRelatedSingle<T extends DataInterface>(
        className: DataClassInterface<T>,
        cache?: CacheExpiration,
    ): Promise<T> {
        const url: string|undefined = this._data.relationships[className.singular].links.related;

        if (url === undefined)
            throw new Error(className.singular + ' missing');

        const response = await this._api.getSingle(className, url, cache ?? className.cacheExpiration);

        if (response === undefined)
            throw new Error(className.singular + ' missing')

        return response;
    }

    protected async getRelatedList<T extends DataInterface>(
        className: DataClassInterface<T>,
        cache?: CacheExpiration,
    ): Promise<T[]> {
        const url: string|undefined = this._data.relationships[className.plural].links.related;

        if (url === undefined)
            throw new Error(className.plural + ' missing');

        return this._api.getList(className, url, cache ?? className.cacheExpiration);
    }
}