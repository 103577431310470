import {Course} from "../../../data/objects/Course";
import i18next from "i18next";
import {useEffect, useState} from "react";
import {Area} from "../../../data/objects/Area";
import {Format} from "../../../data/objects/Format";
import {Level} from "../../../data/objects/Level";
import {Module} from "../../../data/objects/Module";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconProp, library} from "@fortawesome/fontawesome-svg-core";
import {
    faChalkboard,
    faClock,
    faHourglass,
    faIcons,
    faLayerGroup, faPodcast, faStethoscope,
    faTableList
} from "@fortawesome/free-solid-svg-icons";
import {DeliveryStyle} from "../../../data/objects/DeliveryStyle";
import {Methodology} from "../../../data/objects/Methodology";


interface glanceElement {
    icon: IconProp,
    description: string,
    value: string,
}

function getTitleFromList(
    objects: any
): string {
    let response = '';
    for (let index=0; index<objects.length; index++){
        response += objects[index].name;

        if (index < objects.length-1)
            response += ' | ';
    }

    return response;
}


export default function CourseAtAGlanceComponent({course}: {course: Course}){
    library.add(faChalkboard, faStethoscope, faPodcast, faHourglass, faClock, faIcons, faLayerGroup, faTableList);

    const [name, setName] = useState<string>('');
    const [glanceElements] = useState<glanceElement[]>([
        {icon: "clock", description: i18next.t("duration"), value: i18next.t("month", {count: course.duration})},
        {icon: "hourglass", description: i18next.t("delivery"), value: course.delivery},
    ]);

    useEffect(() => {
        course.getChildren(Area)
            .then((areas: Area[]) => {
                if (areas.length !== 0) {
                    const list = getTitleFromList(areas);

                    glanceElements.push({icon: "chalkboard", description: i18next.t("area", {count: areas.length}), value: list});
                    setName('deliveryStyle')
                }
            });

        course.getChild(Format)
            .then((format: Format) => {
                glanceElements.push({icon: "icons", description: i18next.t("format"), value: format.name});
                setName('format');
            });

        course.getChildren(Module)
            .then((modules: Module[]) => {
                glanceElements.push({icon: "table-list", description: i18next.t("content"), value: i18next.t("module", {count: modules.length})});
                setName('format');
            });

        course.getChildren(Level)
            .then((levels: Level[]) => {
                if (levels.length !== 0) {
                    const levelList = getTitleFromList(levels);

                    glanceElements.push({icon: "layer-group", description: i18next.t("level", {count: levels.length}), value: levelList});
                    setName('levels')
                }
            });

        course.getChildren(DeliveryStyle)
            .then((deliveryStyles: DeliveryStyle[]) => {
                if (deliveryStyles.length !== 0) {
                    const list = getTitleFromList(deliveryStyles);

                    glanceElements.push({icon: "podcast", description: i18next.t("delivery style", {count: deliveryStyles.length}), value: list});
                    setName('deliveryStyle')
                }
            });

        course.getChildren(Methodology)
            .then((methodologies: Methodology[]) => {
                if (methodologies.length !== 0) {
                    const list = getTitleFromList(methodologies);

                    glanceElements.push({icon: "stethoscope", description: i18next.t("methodology", {count: methodologies.length}), value: list});
                    setName('methodologies')
                }
            });

    }, [glanceElements, course]);

    return (
        <>
            <h4>{i18next.t("at a glance")}</h4>
            <div className="card mb-5">
                <div className="card-body">
                    {glanceElements.map((element: glanceElement, index: number) => (
                        <div key={index} className="row g-0 align-items-center mb-3">
                            <div className="col-auto">
                                <div className="sw-3 sh-4 d-flex justify-content-center align-items-center">
                                    <FontAwesomeIcon icon={element.icon} className={"icon"} />
                                </div>
                            </div>
                            <div className="col ps-3">
                                <div className="row g-0">
                                    <div className="col">
                                        <div className="sh-4 d-flex align-items-center lh-1-25">{ element.description[0].toUpperCase() + element.description.substr(1) }</div>
                                    </div>
                                    <div className="col-auto">
                                        <span className={"added-information"}>{ element.value }</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}