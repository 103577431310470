import {AbstractData} from "../abstracts/AbstractData";
import {DataInterface} from "../interfaces/DataInterface";
import {DataClassInterface, staticImplements} from "../interfaces/DataClassInterface";
import {CacheExpiration} from "../enums/CacheExpiration";

@staticImplements<DataClassInterface<DataInterface>>()
export class User extends AbstractData implements DataInterface {
    static get singular(): string {
        return "user";
    }

    static get plural(): string {
        return "users";
    }

    static get cacheExpiration(): CacheExpiration {
        return CacheExpiration.NoCache;
    }

    get firstName(): string {
        return this._data.attributes.firstName ?? "";
    }

    get lastName(): string {
        return this._data.attributes.lastName ?? "";
    }

    get email(): string {
        return this._data.attributes.email ?? "";
    }
}