export default function LoginComponent() {
    let url: string = process.env.REACT_APP_AUTH_URL + "auth/index"
        + "?client_id=" + process.env.REACT_APP_AUTH_CLIENT_ID
        + "&state=xyz";

    return (
        <>
            <a href={url}>Login</a>
        </>
    )
}