import {DataInterface} from "../interfaces/DataInterface";
import {DataClassInterface, staticImplements} from "../interfaces/DataClassInterface";
import {CacheExpiration} from "../enums/CacheExpiration";
import {BaseDataInterface} from "../interfaces/BaseDataInterface";
import {AbstractBaseData} from "../abstracts/AbstractBaseData";

@staticImplements<DataClassInterface<DataInterface>>()
export class Methodology extends AbstractBaseData implements BaseDataInterface {
    static get singular(): string {
        return "methodology";
    }

    static get plural(): string {
        return "methodologies";
    }

    static get cacheExpiration(): CacheExpiration {
        return CacheExpiration.Forever;
    }
}