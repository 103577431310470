import {Link} from "react-router-dom";

export interface CardComponentDataInterface {
    title?: string,
    description?: string,
    url?: string,
    image?: string,
    subtitle?: string,
    footnote?: string,
}

export default function CardComponent({data}: {data: CardComponentDataInterface}) {
    return (
        <div className={"card"}>
            {
                data.image ?
                <div className={"card-img-top"}>
                    <img alt={data.title} src={data.image}/>
                </div> : null
            }
            <div className="card-body">
                <h3>{ data.url ? <Link to={ data.url }>{data.title}</Link> : data.title }</h3>
                {data.subtitle && <div className={"added-information mb-2 mt-2"}>{data.subtitle}</div>}
                <div>{data.description}</div>
                {data.footnote && <div className={"added-information mb-2 mt-2"}>{data.footnote}</div>}
            </div>
        </div>
    );
}