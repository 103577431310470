export default function FooterComponent() {
    return (
        <footer>
            <div className="footer-content">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-sm-6">
                            <p className="mb-0 text-muted text-medium">Linknetic Inc.</p>
                        </div>
                        <div className="col-sm-6 d-none d-sm-block">
                            <ul className="breadcrumb pt-0 pe-0 mb-0 float-end">
                                <li className="breadcrumb-item mb-0 text-medium">
                                    <a href="src/components/footer#" target="_blank" className="btn-link">Review</a>
                                </li>
                                <li className="breadcrumb-item mb-0 text-medium">
                                    <a href="src/components/footer#" target="_blank" className="btn-link">Purchase</a>
                                </li>
                                <li className="breadcrumb-item mb-0 text-medium">
                                    <a href="src/components/footer#" target="_blank" className="btn-link">Docs</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}