import {BaseDataInterface} from "../../../data/interfaces/BaseDataInterface";
import {Link} from "react-router-dom";
import {DataClassInterface} from "../../../data/interfaces/DataClassInterface";

export default function CardListComponent({type, data}: {type: DataClassInterface<any>,data: BaseDataInterface[]}) {
    return (
        <div className="card mb-5">
            <div className="card-body">
                <ul className="list-group list-group-flush">
                    {
                        data.map((data: BaseDataInterface, index: number) => (
                            <li className="list-group-item" key={data.id}>
                                <Link to={"/" + type.plural + "/" + data.id}>
                                    {data.name}
                                </Link>
                            </li>
                        ))
                    }
                </ul>
            </div>
        </div>
    );
}