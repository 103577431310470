import {BreadcrumbElementInterface} from "./interfaces/BreadcrumbElementInterface";

export class BreadcrumbElement implements BreadcrumbElementInterface {
    private _title: string;
    private _href: string;

    constructor(
        title: string,
        href: string,
    ) {
        this._title = title;
        this._href = href;
    }

    get href(): string {
        return this._href;
    }

    get title(): string {
        return this._title;
    }
}