import {Routes} from "./Routes";

export class LinkRouter {
    static getLink(
        route: Routes,
        id?: string
    ): string {
        if (id === undefined)
            return route;

        let response = "";

        const routeElements = route.split("/");

        for (let index=0; index<routeElements.length; index++){
            if (routeElements[index] === "")
                continue;

            response += "/";
            if (routeElements[index][0] === ":")
                response += id;
            else
                response += routeElements[index]

        }

        return response;
    }
}