import {BreadcrumbContainerInterface} from "./interfaces/BreadcrumbContainerInterface";
import {BreadcrumbElementInterface} from "./interfaces/BreadcrumbElementInterface";

export class BreadcrumbContainer implements BreadcrumbContainerInterface{
    private _elements: BreadcrumbElementInterface[] = [];

    get elements(): BreadcrumbElementInterface[] {
        return this._elements;
    }

    addElement(
        element:BreadcrumbElementInterface,
    ): BreadcrumbContainerInterface {
        this._elements.push(element);

        return this;
    }
}