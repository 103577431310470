import { library } from "@fortawesome/fontawesome-svg-core";
import { faGear, faTv } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {LocalApiCache} from "../../../data/utils/LocalApiCache";

export default function AdministrationMenuComponent(){
    library.add(faTv);
    return (
        <li className={"item"} id={"administration"}>
            <a href="#administration" className={"btn"}><FontAwesomeIcon icon={faGear} className={"fa"}/>Administration</a>
            <div className={"subMenu"}>
                <a href={"#"} onClick={LocalApiCache.clear}>Clear Cache</a>
                <a href={"#"} onClick={LocalApiCache.dump}>Show Cache</a>
            </div>
        </li>
    );
}



